import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './index.css';

const BongPlayer = () => {
  const { playerId } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [stream, setStream] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  const createStream = async (streamId) => {
    setLoading(true);
    if(streamId){
      const linkType = playerId === '1' ? 'bongls': 'bongls1';
      const link = `https://randomideas.info/${linkType}/?id=${streamId}`;
      setStream(link);
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    window.addEventListener('resize', updateScreenSize);
    const urlParams = new URLSearchParams(window.location.search);
    const streamId = urlParams.get('id');
    createStream(streamId);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <iframe
          title="Video Player"
          width={screenWidth}
          height={screenHeight}
          src={stream}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
);
};

export default BongPlayer;
